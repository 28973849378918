import React from 'react';
import EventDetails from './EventDetails';
import EventTicket from './EventTicket';
import EventSchedule from './EventSchedule';
import EventUpload from './EventUpload';
import EventCustomField from './EventCustomField';
import { Col, Form, Row } from 'react-bootstrap';
import EventOtherInfo from './EventOtherInfo';
import { useForm } from 'react-hook-form';
import { CommonHeader, CommonFooter } from 'components/common';

const InvoiceDetails = () => {
  const { register, handleSubmit, setValue, control } = useForm();
  const onSubmit = data => {
    console.log(data);
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-3">
        <Col xs={12}>
          <CommonHeader title={`Invoice #IV2206-XXXX`} />
        </Col>
        {/* <Col xs={12}>
          <EventBanner />
        </Col> */}
        <Col lg={8}>
          <EventDetails register={register} setValue={setValue} />
          <EventTicket
            register={register}
            control={control}
            setValue={setValue}
          />
          {/* <EventSchedule />
          <EventUpload />
          <EventCustomField register={register} /> */}
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <EventOtherInfo register={register} control={control} setValue={setValue} />
          </div>
        </Col>
        <Col>
          <CommonFooter title={`Nice Job! You're almost done`} />
        </Col>
      </Row>
    </Form>
  );
};

export default InvoiceDetails;
