import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { Provider } from 'react-redux';
import AuthProvider from './utils/authProvider'
import store from './store';
import userManager, { loadUserFromStorage } from './services/userService'

const App = () => {
  return (
    <Provider store={store}>
      <AuthProvider userManager={userManager} store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <Layout />
        </Router>
      </AuthProvider>
    </Provider>
  );
};

export default App;
