import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import 'helpers/initFA';

import {
  RecoilRoot
} from 'recoil';

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
);
