import { UserManager } from 'oidc-client';
import { storeUserError, storeUser } from '../actions/authActions'

// const config1 = {
//   authority: "https://localhost:5001",
//   client_id: "wewantdoughnuts",
//   redirect_uri: "http://localhost:3000/signin-oidc",
//   response_type: "id_token token",
//   scope: "openid profile doughnutapi",
//   post_logout_redirect_uri: "http://localhost:3000/signout-oidc",
// };

const config = {
  token_endpoint: "https://erp22s.devz.sbs/apis/s0/connect/token",
  authorize_endpoint: "https://erp22s.devz.sbs/apis/s0/connect/authorize",
  //authority: "https://erp22s.devz.sbs/apis/s0",
  authority: "https://erp22s.devz.sbs/apis/s0",
  client_id: "zorro",
  //redirect_uri: "https://localhost:3000/signin-oidc",
  redirect_uri: "https://tripz-ui.devz.sbs/signin-oidc",
  automaticSilentRenew: true,
  //response_type: "id_token token",
  response_type: 'code',
  scope: "openid profile fullaccess roles",
  //post_logout_redirect_uri: "https://localhost:3000/signout-oidc",
  post_logout_redirect_uri: "https://tripz-ui.devz.sbs/signout-oidc",
  grant_type: "authorization_code",
  filterProtocolClaims: true,
};


// const settings: any = {
//   userStore: new WebStorageStateStore({ store: window.localStorage }),
//   authority: STS_DOMAIN,
//   client_id: 'vuejs_code_client',
//   redirect_uri: 'https://localhost:44357/callback.html',
//   automaticSilentRenew: true,
//   silent_redirect_uri: 'https://localhost:44357/silent-renew.html',
//   response_type: 'code',
//   scope: 'openid profile dataEventRecords',
//   post_logout_redirect_uri: 'https://localhost:44357/',
//   filterProtocolClaims: true,
// };


const userManager = new UserManager(config)

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser()
    if (!user) { return store.dispatch(storeUserError()) }
    store.dispatch(storeUser(user))
  } catch (e) {
    console.error(`User not found: ${e}`)
    store.dispatch(storeUserError())
  }
}

export function signinRedirect() {
  return userManager.signinRedirect()
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signoutRedirect() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager